import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';

// Define light and dark theme colors
const lightTheme = {
    body: '#FFF',
    text: '#333',
    inputBackground: '#F0F0F0',
    buttonBackground: '#007BFF',
    buttonText: '#FFF',
    buttonHoverBackground: '#0056b3',
    songInfoBackground: '#f7f7f7',
    appearsOnBackground: '#f7f7f7',
};

const darkTheme = {
    body: '#121212',
    text: '#FFF',
    inputBackground: '#333',
    buttonBackground: '#1E90FF',
    buttonText: '#FFF',
    buttonHoverBackground: '#1C86EE',
    songInfoBackground: '#1a1a1a',
    appearsOnBackground: '#1a1a1a',
};

const Container = styled.div`
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    color: ${({ theme }) => theme.text};
`;

const Section = styled.div`
    background-color: none;
    border-radius: 10px;
    text-align: left;
    margin-top: 20px;
`;

const Heading = styled.h2`
    font-size: 20px;
    margin-bottom: 20px;
    font-family: 'Unbounded', sans-serif;
`;

const ReleaseCarouselContainer = styled.div`
    display: flex;
    overflow-x: auto;
    gap: 20px;

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
`;

const ReleaseCard = styled(Link)`
    height: fit-content;
    flex-shrink: 0;
    text-decoration: none;
    color: inherit;
    background-color: none;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.appearsOnBackground}dd;
        transform: scale(1.05);
    }
`;

const ReleaseImage = styled.img`
    height: 350px;
    border-radius: 4px;
`;

const ReleaseName = styled.p`
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Unbounded', sans-serif;
`;

const ErrorMessage = styled.p`
    color: red;
    text-align: center;
`;

function ReleaseCarousel() {
    const [releases, setReleases] = useState([]); // Initialize as an empty array
    const [error, setError] = useState(null);
    const [theme, setTheme] = useState(lightTheme);

    useEffect(() => {
        const systemTheme = window.matchMedia('(prefers-color-scheme: dark)');
        setTheme(systemTheme.matches ? darkTheme : lightTheme);

        const themeChangeListener = (e) => {
            setTheme(e.matches ? darkTheme : lightTheme);
        };

        systemTheme.addEventListener('change', themeChangeListener);

        return () => {
            systemTheme.removeEventListener('change', themeChangeListener);
        };
    }, []);

    useEffect(() => {
        axios
            .get("https://api.fifasongs.com/api/releases")
            .then((response) => {
                //sort by respone.year
                response.data.sort((b, a) => a.year - b.year);
                setReleases(response.data|| []);
            })
            .catch((error) => {
                setError("Failed to load releases");
                console.error(error);
            });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                {releases && releases.length > 0 ? (
                    <Section>
                        <Heading>Browse releases</Heading>
                        <ReleaseCarouselContainer>
                            {releases.map((release) => (
                                <ReleaseCard key={release.id} to={`/release/${release.id}`}>
                                    <ReleaseImage src={`https://api.fifasongs.com/static/releases/${release.id}.webp`} alt={`${release.name} album art`} />
                                    <ReleaseName>{release.name}</ReleaseName>
                                </ReleaseCard>
                            ))}
                        </ReleaseCarouselContainer>
                    </Section>
                ) : (
                    !error && <p>No releases found.</p>
                )}
            </Container>
        </ThemeProvider>
    );
}

export default ReleaseCarousel;
