import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

// Define light and dark theme colors
const lightTheme = {
  body: '#FFF',
  text: '#333',
  cardBackground: '#f7f7f7',
  accentColor: '#007BFF',
  accentHover: '#0056b3',
  borderColor: '#ddd',
};

const darkTheme = {
  body: '#121212',
  text: '#FFF',
  cardBackground: '#1a1a1a',
  accentColor: '#1E90FF',
  accentHover: '#1C86EE',
  borderColor: '#333',
};

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: ${({ theme }) => theme.text};
`;

const ReleaseInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const ReleaseArt = styled.img`
  height: 200px;  // Fixed height
  width: auto;    // Auto width to maintain aspect ratio
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
`;


const ReleaseDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ReleaseTitle = styled.h1`
  margin: 0;
  font-size: 28px;
  color: ${({ theme }) => theme.text};
`;

const ReleaseYear = styled.p`
  margin: 10px 0 0;
  font-size: 18px;
  color: ${({ theme }) => theme.text};
`;

const SongsSection = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  color: ${({ theme }) => theme.text};
  text-align: center;
`;

const SongList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const SongItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.accentColor}20;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const SongArt = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 15px;
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const SongTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const SongArtist = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.text}aa;
`;

const BackLink = styled(Link)`
  display: inline-block;
  margin-top: 30px;
  text-decoration: none;
  color: ${({ theme }) => theme.accentColor};
  font-weight: bold;
  text-align: center;
  width: 100%;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.accentHover};
  }
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-size: 18px;
`;

const ErrorMessage = styled.p`
  text-align: center;
  font-size: 18px;
  color: red;
`;

function ReleaseDetail() {
  const { id } = useParams();
  const [release, setRelease] = useState(null);
  const [songs, setSongs] = useState([]);
  const [error, setError] = useState('');
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(systemTheme.matches ? darkTheme : lightTheme);

    const themeChangeListener = (e) => {
      setTheme(e.matches ? darkTheme : lightTheme);
    };

    systemTheme.addEventListener('change', themeChangeListener);

    return () => {
      systemTheme.removeEventListener('change', themeChangeListener);
    };
  }, []);

  useEffect(() => {
    const fetchReleaseData = async () => {
      try {
        const [releaseResponse, songsResponse] = await Promise.all([
          axios.get(`https://api.fifasongs.com/api/releases/${id}/`),
          axios.get(`https://api.fifasongs.com/api/releases/${id}/songs/`),
        ]);

        setRelease(releaseResponse.data);
        setSongs(songsResponse.data);
      } catch (err) {
        console.error('Error fetching release details:', err);
        setError('Failed to load release details. Please try again later.');
      }
    };

    fetchReleaseData();
  }, [id]);

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <ErrorMessage>{error}</ErrorMessage>
          <BackLink to="/">Go Back to Search</BackLink>
        </Container>
      </ThemeProvider>
    );
  }

  if (!release || songs.length === 0) {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <LoadingMessage>Loading...</LoadingMessage>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <ReleaseInfoContainer>
          <ReleaseArt
            src={`https://api.fifasongs.com/static/releases/${release.id}.webp`}
            alt={`${release.name} cover art`}
          />
          <ReleaseDetails>
            <ReleaseTitle>{release.name}</ReleaseTitle>
            <br />
            <ReleaseYear>{release.year}</ReleaseYear>
          </ReleaseDetails>
        </ReleaseInfoContainer>

        <SongsSection>
          <SectionTitle>Songs</SectionTitle>
          <SongList>
            {songs.map((song) => (
              <SongItem key={song.id} to={`/song/${song.id}`}>
                <SongArt
                  src={`https://api.fifasongs.com/static/songs/${song.id}.webp`}
                  alt={`${song.title} cover art`}
                />
                <SongInfo>
                  <SongTitle>{song.title}</SongTitle>
                  <SongArtist>{song.artist}</SongArtist>
                </SongInfo>
              </SongItem>
            ))}
          </SongList>
        </SongsSection>

        <BackLink to="/">Go Back to Search</BackLink>
      </Container>
    </ThemeProvider>
  );
}

export default ReleaseDetail;
