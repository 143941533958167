import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({ $isOpen }) => ($isOpen ? '0' : '-100%')};
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  padding: 20px;
  transition: right 0.3s ease;
  z-index: 1000;
  box-shadow: ${({ $isOpen }) => ($isOpen ? '-2px 0 10px rgba(0, 0, 0, 0.5)' : 'none')};
`;

const Overlay = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Unbounded', sans-serif;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function Sidebar({ isOpen, onClose }) {
  // Trap focus in sidebar when it's open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Prevent body scrolling when sidebar is open
    } else {
      document.body.style.overflow = ''; // Reset overflow when sidebar is closed
    }

    return () => {
      document.body.style.overflow = ''; // Clean up on unmount
    };
  }, [isOpen]);

  return (
    <>
      {/* Overlay to close sidebar when clicking outside */}
      <Overlay $isOpen={isOpen} onClick={onClose} />
      <SidebarContainer $isOpen={isOpen} aria-hidden={!isOpen}>
        <CloseButton onClick={onClose} aria-label="Close Sidebar">✖</CloseButton>
        <NavLinks>
          <Link to="/">Home</Link>
          {/* Add more links as needed */}
        </NavLinks>
      </SidebarContainer>
    </>
  );
}

export default Sidebar;
