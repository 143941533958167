import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
// Removed unused import: debounce

const HeaderContainer = styled.header`
  width: 100%;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box; /* Ensure padding is included in width */
`;

const Title = styled.h1`
  margin: 0;
  font-family: 'Unbounded', sans-serif;
  color: ${({ theme }) => theme.text};
  font-size: 24px;
  flex-grow: 1; /* Allows the title to take up available space */
  white-space: nowrap; /* Prevents title from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Truncates long titles with an ellipsis */
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  font-size: 24px;
  cursor: pointer;
  flex-shrink: 0; /* Prevents the button from shrinking */
  margin-left: auto; /* Pushes the button to the right */
  
  &:hover {
    opacity: 0.8;
  }
`;

// Define light and dark theme colours
const lightTheme = {
  body: '#FFF',
  text: '#333',
  inputBackground: '#F0F0F0',
  buttonBackground: '#007BFF',
  buttonText: '#FFF',
  buttonHoverBackground: '#0056b3',
};

const darkTheme = {
  body: '#121212',
  text: '#FFF',
  inputBackground: '#333',
  buttonBackground: '#1E90FF',
  buttonText: '#FFF',
  buttonHoverBackground: '#1C86EE',
};

function Header({ onMenuClick }) {
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(systemTheme.matches ? darkTheme : lightTheme);

    const themeChangeListener = (e) => {
      setTheme(e.matches ? darkTheme : lightTheme);
    };

    systemTheme.addEventListener('change', themeChangeListener);

    return () => {
      systemTheme.removeEventListener('change', themeChangeListener);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HeaderContainer>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Title>FIFA Songs</Title>
        </Link>
        <MenuButton 
          onClick={onMenuClick}
          aria-label="Open menu" /* Improved accessibility */
        >
          ☰
        </MenuButton>
      </HeaderContainer>
    </ThemeProvider>
  );
}

export default Header;
