import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Search from './components/Search';
import ReleaseDetail from './components/ReleaseDetail';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import SongInfo from './components/SongInfo';
import { useEffect } from "react";


function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Router>
      <Header onMenuClick={toggleSidebar} />
      <Sidebar isOpen={sidebarOpen} onClose={toggleSidebar} />
      <Routes>
        <Route path="/" element={<Search />} />
        <Route path="/release/:id" element={<ReleaseDetail />} />
        <Route path="/song/:id" element={<SongInfo />} />
      </Routes>
    </Router>
  );
}

export default App;
