import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce'; 
import ReleaseCarousel from './ReleaseCarousel';
// Global styles that adapt to light/dark mode
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.25s linear;
  }
`;

// Define light and dark theme colors
const lightTheme = {
  body: '#FFF',
  text: '#333',
  inputBackground: '#F0F0F0',
  buttonBackground: '#007BFF',
  buttonText: '#FFF',
  buttonHoverBackground: '#0056b3',
};

const darkTheme = {
  body: '#121212',
  text: '#FFF',
  inputBackground: '#333',
  buttonBackground: '#1E90FF',
  buttonText: '#FFF',
  buttonHoverBackground: '#1C86EE',
};

// Container and other components styled with theme
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 48px;
  font-family: 'Unbounded', sans-serif;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  font-family: 'Unbounded', sans-serif;
  background-color: ${({ theme }) => theme.inputBackground};
  border: 1px solid #ccc;
  border-radius: 4px;
  color: ${({ theme }) => theme.text};
`;

const ResultsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
`;

const ResultItem = styled(Link)`
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'Unbounded', sans-serif;
  color: inherit;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.inputBackground};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const AlbumArt = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 15px;
  object-fit: cover;
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.buttonBackground};
  text-decoration: none;
  font-weight: bold;
  margin-top: 5px;
  transition: color 0.25s;

  &:hover {
    color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

function Search() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(systemTheme.matches ? darkTheme : lightTheme);

    const themeChangeListener = (e) => {
      setTheme(e.matches ? darkTheme : lightTheme);
    };

    systemTheme.addEventListener('change', themeChangeListener);

    return () => {
      systemTheme.removeEventListener('change', themeChangeListener);
    };
  }, []);

  const fetchResults = async (searchQuery) => {
    if (!searchQuery || searchQuery.length < 3) {
      setResults([]);
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://api.fifasongs.com/api/songs/`, {
        params: { search: searchQuery },
      });
      setResults(response.data);
    } catch (error) {
      setError('Error searching for songs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchResults = debounce(fetchResults, 300);
    debouncedFetchResults(query);

    // Cleanup function to cancel any debounced function calls on component unmount
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [query]); // Only re-run when query changes

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Title>Every game. Every song.</Title>
      <Container>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search by song title or artist..."
          />
        </Form>
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {results.length > 0 &&
        <ResultsList>
          {results.map((song) => (
            <ResultItem key={song.id} to={`/song/${song.id}`}>
              <AlbumArt src={`https://api.fifasongs.com/static/songs/${song.id}.webp`} alt={`${song.title} album art`} />
              <SongInfo>
                <span>{song.title} - {song.artist}</span>
                {song.releases && song.releases.length > 0 &&
                  song.releases.map((release) => (
                    <StyledLink key={release.release_id} to={`/release/${release.release_id}`} onClick={(e) => e.stopPropagation()}>
                      {release.release_name}
                    </StyledLink>
                  ))
                }
              </SongInfo>
            </ResultItem>
          ))}
        </ResultsList>
}
      </Container>
      <ReleaseCarousel />
    </ThemeProvider>
  );
}

export default Search;