import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { ThemeProvider } from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { faSpotify, faApple, faYoutube, faAmazon, faSoundcloud, faYoutubeSquare, faYandex, faDeezer, faItunes, faNapster } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLink} from '@fortawesome/free-solid-svg-icons';

// Define light and dark theme colors
const lightTheme = {
  body: '#FFF',
  text: '#333',
  inputBackground: '#F0F0F0',
  buttonBackground: '#007BFF',
  buttonText: '#FFF',
  buttonHoverBackground: '#0056b3',
  songInfoBackground: '#f7f7f7',
  appearsOnBackground: '#f7f7f7',
};

const darkTheme = {
  body: '#121212',
  text: '#FFF',
  inputBackground: '#333',
  buttonBackground: '#1E90FF',
  buttonText: '#FFF',
  buttonHoverBackground: '#1C86EE',
  songInfoBackground: '#1a1a1a',
  appearsOnBackground: '#1a1a1a',
};

const Container = styled.div`
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  color: ${({ theme }) => theme.text};
`;

const SongInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.songInfoBackground};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  font-family: 'Unbounded', sans-serif;
`;

const AlbumArt = styled.img`
  width: 33%;
  max-width: 200px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  object-fit: contain;
`;


const SongDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: spaced-evenly;
  gap: 20px;
`;

const SongDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InfoText = styled.p`
  margin: 5px 0;
  font-family: 'Unbounded', sans-serif;
`;

const Section = styled.div`
  background-color: ${({ theme }) => theme.appearsOnBackground};
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  margin-top: 20px;
`;

const Heading = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
  font-family: 'Unbounded', sans-serif;
`;

const ReleaseCarousel = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 10px;

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
`;

const ReleaseCard = styled(Link)`
  width: 150px;
  flex-shrink: 0;
  text-decoration: none;
  color: inherit;
  background-color: ${({ theme }) => theme.appearsOnBackground};
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s, background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.appearsOnBackground}dd;
    transform: scale(1.05);
  }
`;

const ReleaseImage = styled.img`
  width: 100%;
  border-radius: 4px;
`;

const ReleaseName = styled.p`
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Unbounded', sans-serif;
`;

const ListenLinks = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListenLinkItem = styled.li`
  margin: 10px 0;
`;

const ListenLink = styled.a`
  color: ${({ theme }) => theme.buttonBackground};
  text-decoration: none;
  font-weight: bold;
  font-family: 'Unbounded', sans-serif;

  &:hover {
    color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const BackLink = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.buttonBackground};
  font-weight: bold;
  
  &:hover {
    color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-family: 'Unbounded', sans-serif;
`;


// Mapping of URL keys to Font Awesome icons  | apple | youtube | tidal | amazon | soundcloud | youtube_music | song_id | anghami | audiomack | audius | boomplay | deezer | itunes | napster | pandora | spinrilla | yandex |
const iconMap = {
    spotify: faSpotify,
    apple: faApple,
    youtube: faYoutube,
    tidal: faLink,
    amazon: faAmazon,
    soundcloud: faSoundcloud,
    youtube_music: faYoutubeSquare,
    anghami: faLink,
    audiomack: faLink,
    audius: faLink,
    boomplay: faLink,
    deezer: faDeezer,
    itunes: faItunes,
    napster: faNapster,
    pandora: faLink,
    spinrilla: faLink,
    yandex: faYandex,

  };

function SongInfo() {
  const { id } = useParams();
  const [song, setSong] = useState(null);
  const [error, setError] = useState('');
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(systemTheme.matches ? darkTheme : lightTheme);

    const themeChangeListener = (e) => {
      setTheme(e.matches ? darkTheme : lightTheme);
    };

    systemTheme.addEventListener('change', themeChangeListener);

    return () => {
      systemTheme.removeEventListener('change', themeChangeListener);
    };
  }, []);

  useEffect(() => {
    const fetchSong = async () => {
      try {
        const response = await axios.get(`https://api.fifasongs.com/api/songs/${id}`);
        setSong(response.data);
      } catch (error) {
        console.error('Error fetching song details:', error);
        setError('Error fetching song details. Please try again.');
      }
    };

    fetchSong();
  }, [id]);

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <ErrorMessage>{error}</ErrorMessage>
          <BackLink to="/">Go Back to Search</BackLink>
        </Container>
      </ThemeProvider>
    );
  }

  if (!song) {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <p>Loading...</p>
        </Container>
      </ThemeProvider>
    );
  }

return (
    <ThemeProvider theme={theme}>
        <Container>
            <SongInfoContainer>
                <SongDetailsContainer>
                    <AlbumArt src={`https://api.fifasongs.com/static/songs/${song.id}.webp`} alt={`${song.title} album art`} />
                    <SongDetails>
                        <Title>{song.title}</Title>
                        <br />
                        <InfoText>{song.artist}</InfoText>
                    </SongDetails>
                </SongDetailsContainer>
            </SongInfoContainer>

            {song.releases.length > 0 && (
                <Section>
                    <Heading>Featured on:</Heading>
                    <ReleaseCarousel>
                        {song.releases.map((release) => (
                            <ReleaseCard key={release.release_id} to={`/release/${release.release_id}`}>
                                <ReleaseImage src={`https://api.fifasongs.com/static/releases/${release.release_id}.webp`} alt={`Release ${release.release_id} album art`} />
                                <ReleaseName>{`${release.release_name}`}</ReleaseName>
                            </ReleaseCard>
                        ))}
                    </ReleaseCarousel>
                </Section>
            )}

            {song.urls.length > 0 && (
                <Section>
                    <Heading>Listen</Heading>
                    <ListenLinks>
                        {Object.entries(song.urls[0]).map(([platform, url]) => (
                            platform !== 'id' && platform !== 'song' && url && (
                                <ListenLinkItem key={platform}>
                                    <ListenLink href={url} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={iconMap[platform] || faLink} />
                                        
                                        {" " + (platform === 'youtube_music' ? 'YouTube Music' : platform.charAt(0).toUpperCase() + platform.slice(1))}
                                    </ListenLink>
                                </ListenLinkItem>
                            )
                        ))}
                    </ListenLinks>
                </Section>
            )}
            
            <BackLink to="/">Go Back to Search</BackLink>
        </Container>
    </ThemeProvider>
);
}

export default SongInfo;
